import React, {useEffect, useState} from 'react'
import { Dropdown, Grid, Segment, Input, Button, Icon } from 'semantic-ui-react'
import {useForm, Controller} from "react-hook-form"
import './style.css'

const Google = () => {
    const {
        register,
        control,
        handleSubmit
    } = useForm()

    const [projects, setProject] = useState([])
    const [language, setLanguage] = useState([])

    async function adProject(){
        const response = await fetch('https://crm.axcap.ae/rest/1/y9x9q1wmj1mwq5bu/crm.lead.userfield.list?filter%5BFIELD_NAME%5D=UF_CRM_1568907438');
        const data = await response.json();
        setProject(data.result[0].LIST)
    }
    async function adLang(){
        const response = await fetch('https://crm.axcap.ae/rest/1/y9x9q1wmj1mwq5bu/crm.lead.userfield.list?filter%5BFIELD_NAME%5D=UF_CRM_1553165368131');
        const data = await response.json();
        setLanguage(data.result[0].LIST)
    }
    
    useEffect(() => {
        adProject();
        adLang();
    }, [])


    const transformProject = projects.map(project => ({
        key: project.ID,
        text: project.VALUE,
        value: project.ID
    }))
    const transformLanguage = language.map(language => ({
        key: language.ID,
        text: language.VALUE,
        value: language.ID
    }))

    const onSubmit = (fields) => {
        console.log(fields)

            const response = fetch("https://api.axc.ae/webhook/b1acf1f9-ffab-4131-9379-7bfb7cea29fe", {
                method: 'POST',
                mode: 'cors', 
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json'
                },
                redirect: 'follow',
                referrerPolicy: 'no-referrer',
                body: JSON.stringify({fields, "source": "google"}) 
            });
    
        
    }

    
    console.log(transformProject)
    return(
        <>
            <h1 className='title'>- Add Google Form -</h1>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container columns='equal' className='grid-form'>
                    <Grid.Row>
                        <Grid.Column>
                            <div className='item'>
                                <span>
                                    Form ID
                                </span>
                                <Controller
                                    name='form_id'
                                    defaultValue=""
                                    control={control}
                                    render={({field}) => <Input type='text' placeholder='Insert...' {...field}/>}
                                />
                                
                            </div>
                        </Grid.Column>
                        <Grid.Column>
                            {/* <Segment>2</Segment> */}
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <div className='item'>
                                <span>
                                    Project
                                </span>
                                <Controller
                                    name='Ad_Project'
                                    control={control}
                                    render={({ field: { onChange, value, ...field } }) => (
                                        <Dropdown
                                            placeholder='Select Project'
                                            fluid
                                            search
                                            selection
                                            options={transformProject}
                                            value={value}
                                            onChange={(e, data) => onChange(data.value)}
                                            {...field}
                                        />
                                    )} 
                                />
                            </div>
                        </Grid.Column>
                        <Grid.Column>
                        <div className='item'>
                                <span>
                                    Language
                                </span>
                                <Controller
                                    name='Ad_Language'
                                    control={control}
                                    render={({ field: { onChange, value, ...field } }) => (
                                        <Dropdown
                                            placeholder='Select Language'
                                            fluid
                                            search
                                            selection
                                            options={transformLanguage}
                                            value={value}
                                            onChange={(e, data) => onChange(data.value)}
                                            {...field}
                                        />
                                    )} 
                                />
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <div className='item'>
                                <span>
                                    Source Details
                                </span>
                                <Controller
                                    name='source_detail'
                                    defaultValue=""
                                    control={control}
                                    render={({field}) => <Input placeholder='Insert...' {...field}/>}
                                />
                            </div>
                        </Grid.Column>
                        <Grid.Column>
                        <div className='item'>
                                <span>
                                    Created by:
                                </span>
                                <Controller
                                    name='marker'
                                    defaultValue=""
                                    control={control}
                                    render={({field}) => <Input placeholder='Insert...' {...field}/>}
                                />
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                    <Button animated className='fb-button'>
                        <Button.Content visible>Create</Button.Content>
                        <Button.Content hidden>
                            <Icon name='arrow right' />
                        </Button.Content>
                    </Button>
                </Grid>
            </form>
        </>
    )
}

export default Google;
