import React from "react";
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import FB from "../Pages/FB";
import Snapchat from "../Pages/SnapChat";
import Twillio from "../Pages/Twillio";
import Google from "../Pages/Google";

const Controller = () => {
    return <>
        <Routes>
            <Route exact path="/" element={<FB/>}/>
            <Route exact path="/Snapchat" element={<Snapchat/>}/>
            <Route exact path="/Twillio" element={<Twillio/>}/>
            <Route exact path="/Google" element={<Google/>}/>
        </Routes>
    </>
}

export default Controller;