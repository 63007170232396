import React, {useState, useEffect} from "react"
import { 
    Grid, 
    GridColumn, 
    Tab, 
    TabPane,
    TableRow,
    TableHeaderCell,
    TableHeader,
    TableFooter,
    TableCell,
    TableBody,
    Label,
    Table,
    Input,
    Button
 } from "semantic-ui-react"
import axios from "axios";

const IntegrationTable = () => {
    // const [data, setData] = useState([
    //     { id: 1, code: 'ABC123', details: 'Source 1', responsible: 'John Doe' },
    //     { id: 2, code: 'XYZ456', details: 'Source 2', responsible: 'Jane Smith' },
    //     { id: 3, code: 'DEF789', details: 'Source 3', responsible: 'Alice Brown' },
    //     { id: 4, code: 'GHI101', details: 'Source 4', responsible: 'Bob White' },
    // ]);
    const [data, setData] = useState([])
    const [projects, setAd_project] = useState([])
    const [languages, setLanguage] = useState([])
    const [searchTerm, setSearchTerm] = useState('')
    

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.post(process.env.REACT_APP_API_requests + '?request=twilioDataIntegrations', {})
                setData(response.data);
            } catch (error) {
                console.error('ошибка ', error)
            }
        }
        
        const adProjectData = async () => {
            try {
                const response = await axios.post('https://crm.axcap.ae/rest/1/y9x9q1wmj1mwq5bu/crm.lead.userfield.list?filter%5BFIELD_NAME%5D=UF_CRM_1568907438', {})
                setAd_project(response.data.result[0].LIST);
            } catch (error) {
                console.error('ошибка ', error)
            }
        }

        const adLanguage = async () => {
            try {
                const response = await axios.post('https://crm.axcap.ae/rest/1/y9x9q1wmj1mwq5bu/crm.lead.userfield.list?filter%5BFIELD_NAME%5D=UF_CRM_1553165368131', {})
                setLanguage(response.data.result[0].LIST);
            } catch (error) {
                console.error('ошибка ', error)
            }
        }

        adLanguage()
        adProjectData()
        fetchData()
    }, [])

    const getProjectValue = (projectId) => {
        const project = projects.find(p => p.ID === projectId?.toString())
        return project ? project.VALUE : 'Undefined';
    }

    const getLanguageValue = (languageID) => {
        const language = languages.find(p => p.ID === languageID?.toString())
        return language ? language.VALUE : 'Undefined';
    }

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value)
    }


    const filteredData = data.filter(item =>
        item.id.toString().includes(searchTerm) ||
        item.code.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item['source details'].toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.responsible.toString().includes(searchTerm) ||
        item.marker?.toLowerCase().includes(searchTerm.toLowerCase())
    );


    console.log(filteredData)

    return(
        <div>
            <Input
                icon='search'
                placeholder='Search...'
                value={searchTerm}
                onChange={handleSearchChange}
                style={{ marginBottom: '10px' }}
            />
            <Table celled>
                <TableHeader>
                    <TableRow>
                        <TableHeaderCell>ID</TableHeaderCell>
                        <TableHeaderCell>Code</TableHeaderCell>
                        <TableHeaderCell>Source Details</TableHeaderCell>
                        <TableHeaderCell>Project</TableHeaderCell>
                        <TableHeaderCell>Language</TableHeaderCell>
                        <TableHeaderCell>Hidden Source</TableHeaderCell>
                        <TableHeaderCell>Marker</TableHeaderCell>
                        <TableHeaderCell>Responsible</TableHeaderCell>
                    </TableRow>
                </TableHeader>

                <TableBody>
                    {filteredData.map((item) => (
                        <TableRow key={item.id}>
                            <TableCell>{item.id}</TableCell>
                            <TableCell>{item.code}</TableCell>
                            <TableCell>{item['source details']}</TableCell>
                            <TableCell>{getProjectValue(item.ad_project)}</TableCell>
                            <TableCell>{getLanguageValue(item.ad_language)}</TableCell>
                            <TableCell>{item.hidden_source}</TableCell>
                            <TableCell>{item.marker}</TableCell>
                            <TableCell>{item.responsible == 1 ? "AX CAPITAL" : "INDIVIDUAL"}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>

                <TableFooter>
                <TableRow>
                    <TableHeaderCell colSpan='8'>
                    {/* <Menu floated='right' pagination>
                        <MenuItem as='a' icon>
                        <Icon name='chevron left' />
                        </MenuItem>
                        <MenuItem as='a'>1</MenuItem>
                        <MenuItem as='a'>2</MenuItem>
                        <MenuItem as='a'>3</MenuItem>
                        <MenuItem as='a'>4</MenuItem>
                        <MenuItem as='a' icon>
                        <Icon name='chevron right' />
                        </MenuItem>
                    </Menu> */}
                    </TableHeaderCell>
                </TableRow>
                </TableFooter>
            </Table>
        </div>
    )
}

const DuplicatesTable = () => {
    const [data, setData] = useState([])
    const [responseReset, setRespondReset] = useState('')

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.post(process.env.REACT_APP_API_requests + '?request=twilioDataDuplicates', {})
                setData(response.data)
            } catch (error) {
                console.log('ошибка', error)
            }            
        }

        fetchData();
    }, [])

    const resetFilter = async () => {
        try {
            const response = await axios.post(process.env.REACT_APP_API_requests + '?request=resetTwilioDataDuplicates', {})
            console.log(response.data)
            setRespondReset(response.data)
        } catch (error) {
            console.error('ошибка', error)
        }
    }
    

    return (
        <div>
            <Button onClick={resetFilter} secondary>Reset Filter</Button>
            {responseReset && (
                <p className="filter-notes">Filter reseted</p>
            )}
            <Table celled>
                <TableHeader>
                <TableRow>
                    <TableHeaderCell>Phone</TableHeaderCell>
                    <TableHeaderCell>ProfileName</TableHeaderCell>
                </TableRow>
                </TableHeader>

                <TableBody>
                    {data.length > 0 ? (
                        data.map((item, index) => (
                            <TableRow>
                                <TableCell>{item.phone}</TableCell>
                                <TableCell>{item.ProfileName}</TableCell>
                            </TableRow>
                        ))
                    ) : (
                        <TableRow>
                            <TableCell colSpan="2" style={{ textAlign: 'center' }}>
                                Empty
                            </TableCell>
                        </TableRow>
                    )}
                    
                </TableBody>

                <TableFooter>
                <TableRow>
                    <TableHeaderCell colSpan='2'>
                    {/* <Menu floated='right' pagination>
                        <MenuItem as='a' icon>
                        <Icon name='chevron left' />
                        </MenuItem>
                        <MenuItem as='a'>1</MenuItem>
                        <MenuItem as='a'>2</MenuItem>
                        <MenuItem as='a'>3</MenuItem>
                        <MenuItem as='a'>4</MenuItem>
                        <MenuItem as='a' icon>
                        <Icon name='chevron right' />
                        </MenuItem>
                    </Menu> */}
                    </TableHeaderCell>
                </TableRow>
                </TableFooter>
            </Table>
        </div>
    )
}

const panes = [
    { menuItem: 'Twilio Integrations', render: () => <TabPane><IntegrationTable/></TabPane> },
    { menuItem: 'Twilio Filter', render: () => <TabPane><DuplicatesTable/></TabPane> }
]

export default function TwilioData(){
    return(
        <>
            <div className="twilio-dataTable">
                <Grid container columns={1}>
                    <GridColumn>
                        <Tab panes={panes}/>
                    </GridColumn>
                </Grid>
            </div>
        </>
    )
}