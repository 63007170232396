import React, { useState, useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import Controller from './Controller/Controller';
import 'semantic-ui-css/semantic.min.css'

function App() {
  const [authorized, setAuthorized] = useState(false)

  useEffect(() => {
    const handleParentMessage = (event) => {
      if (event.data.type === 'PARENT_URL') {
        const parentUrl = event.data.url;

        const allowedDomain = 'https://crm.axcap.ae';
        if (parentUrl.startsWith(allowedDomain)) {
          setAuthorized(true);
        } else {
          setAuthorized(false);
        }
      }
    };

    window.addEventListener('message', handleParentMessage);

    return () => {
      window.removeEventListener('message', handleParentMessage);
    };
  }, []);

  if(!authorized){
    return <>Access Denied</>
  }
  return (
    <div className="App">
      <Controller/>
    </div>
  );
}

export default App;
